<!-- 实时数据设备详情 -->
<template>
  <div id="deviceInfo">
    <div class="part1">
      <!-- 设备数据 -->
      <div class="real-data">
        <div class="title">
          <span class="online" v-show="data.online=='1'">【在线】</span>
          <span class="offline" v-show="data.online=='0'">【离线】</span>
          <span class="sleep" v-show="data.online=='2'">【休眠】</span>&emsp;
          <span>设备：{{data.title}} ({{data.id}})</span>
          <span style="margin-left: 20px;">{{data.lastAlive}}</span>
          <span @click="viewMenuList" style="padding-left: 20px;"><i style="padding-right:4px" class="m-iconfont icon-menu"></i>设备配置</span>
        </div>
        <template v-if="data.model=='swLora' || data.model=='dmWater' || data.model == 'dmMaster'">
          <div class="emty-class" v-if="fields.length==0">暂无数据</div>
          <el-tabs v-model="activeNodeName" @tab-click="handleClickTable">
            <el-tab-pane v-for="(filed,i) of fields" v-bind:key="i" :name="i.toString()">
              <span slot="label" class="tab-node">{{filed|nodeNameFilters(i)}}</span>
              <div class="value">
                <template v-for="(item,index) of filed">
                  <div class="data-item" v-if="!item.hide && item.value" v-bind:key="item.title" @click="fieldClick(item)">
                    <div class="left">
                      <i class="m-iconfont" :class="'icon-' + item.key" :style="{color:colors[index]}"></i>
                      <!-- <span>{{item.title.substring(0,1)}}</span> -->
                    </div>
                    <div class="right">
                      <span>{{item.value}}</span>
                      <span>{{item.title}} {{item.unit==''?'':`(${item.unit})`}}</span>
                    </div>
                  </div>
                </template>
              </div>
            </el-tab-pane>
          </el-tabs>
        </template>
        <template v-else>
          <div class="value">
            <template v-for="(item,index) of fields">
              <div class="data-item" v-if="!item.hide && item.value" v-bind:key="item.title" @click="fieldClick(item)">
                <div class="left">
                  <i class="m-iconfont" :class="'icon-' + item.key" :style="{color:colors[index]}"></i>
                  <!-- <span>{{item.title.substring(0,1)}}</span> -->
                </div>
                <div class="right">
                  <span>{{item.value}}</span>
                  <span>{{item.title}} {{item.unit==''?'':`(${item.unit})`}}</span>
                </div>
              </div>
            </template>
          </div>
        </template>
      </div>
      <!-- 今日数据 -->
      <div class="history-data">
        <div class="title">今日数据</div>
        <div class="value">
          <div class="emty-class" v-if="fields.length==0">暂无数据</div>
          <PhaseData ref="phase" v-else :device="phaseData_device" :field="phaseData_field" :activeNodeName="activeNodeName">
          </PhaseData>
        </div>

      </div>
    </div>
    <div class="part2" v-show="videoData.url||mapData.hasMarker">
      <div class="video" v-show="videoData.url">
        <Video :data="videoData"></Video>
      </div>
      <div class="map" v-show="mapData.hasMarker">
        <baidu-map class="baidu-map" :center="mapData.center" :zoom="mapData.zoom" :scroll-wheel-zoom="true" @ready="handlerGps" v-loading="map_loading" element-loading-text="地图加载中" element-loading-spinner="el-icon-loading" element-loading-background="rgba(255, 255, 255, 1)">
          <bm-marker :position="{lng: mapData.marker.lng, lat: mapData.marker.lat}" animation="BMAP_ANIMATION_BOUNCE" v-if="mapData.hasMarker">
            <bm-info-window :show="true">
              GPS坐标位置：<br /><br /> {{mapData.marker.lng}}，{{mapData.marker.lat}}
            </bm-info-window>
          </bm-marker>
        </baidu-map>
      </div>
    </div>
    <menuList :dialogObj="dialogObj"></menuList>
  </div>
</template>

<script>
import Video from './Video.vue';
import PhaseData from './PhaseData.vue';
import Menu from './Menu.vue';

const menuList = () => import("@/views/second/menu_list.vue")

export default {
  data() {
    return {
      colors: [],
      mapObj: null,
      // 设备坐标
      mapData: {
        center: {
          lng: 116.401394,
          lat: 39.916042
        },
        marker: {
          lng: '',
          lat: ''
        },
        zoom: 15,
        hasMarker: false
      },
      // 实时数据
      fields: [],
      // 今日历史数据
      phaseData_device: {},
      phaseData_field: {},
      // 视频数据
      videoData: {
        url: '',
        id: '',
      },
      activeNodeName: "0",
      map_loading: true,
      dialogObj: {
        flag: false,
        title: '',
        deviceUid: ''
      },
    }
  },
  props: {
    map: {
      type: Boolean,
      default: true
    },
    data: {
      type: Object
    }
  },
  components: {
    Video,
    PhaseData,
    Menu,
    menuList
  },
  watch: {
    data() {
      this.videoRequest();
      this.gpsRequest();
    },
    'data.datas': {
      handler(val) {
        if (this.data.model == 'swLora' || this.data.model == 'dmWater' || this.data.model == 'dmMaster') {
          this.fields = this.setFieldsDataArray()
        } else {
          this.fields = this.setFieldsData(this.data);
        }
      },
      deep: true
    }
  },
  filters: {
    nodeNameFilters: function (val, i) {
      if (!val) {
        return '节点'
      }
      let item = val.filter(e => e.key == 'n')
      let num = val.filter(e => e.key == 'i')
      return item[0].title + num[0].value
    }
  },
  methods: {
    handlerGps({
      BMap,
      map
    }) {
      this.mapObj = map;
      // var view = map.getViewport(eval([this.mapData.center]));
      // this.mapData.zoom = view.zoom;
      // this.mapData.center = view.center;
    },
    setFieldsDataArray() {
      let arr = new Array();
      this.data.datas.forEach(e => {
        arr.push(this.setFieldsData({
          ...this.data,
          datas: e
        }))
      })
      return arr
    },

    handleClickTable(tab, event) {
      // this.$refs.phase.updatedData(tab.index)  //未使用的方法
    },
    setFieldsData(data) {
      let profile = this.$store.state.profile;
      if (profile) {
        if (data.model == undefined || data.model == "") {
          return [];
        }
        let fields = Object.assign({}, profile.profile.models[data.model].fields);
        let arr = [];
        for (let item in fields) {
          let obj = Object.assign({}, fields[item]);
          let key = item;
          let value = "";
          let rawValue = "";
          if (data.datas) {
            for (let item2 of data.datas) {
              if (item == item2.key) {
                key = item2.key;
                value = item2.value;
                rawValue = item2.rawValue;
                break;
              }
            }
          }
          let valueMap = fields[item].valueMap;
          if (valueMap) {
            obj.valueMap = valueMap;
            obj.valueMaps = profile.profile.models[data.model].valueMaps[valueMap];
          }
          obj.key = key;
          obj.value = value;
          obj.rawValue = rawValue;
          arr.push(obj);
        }
        return arr;
      } else {
        return [];
      }
    },
    setChartData() {
      setTimeout(() => {
        let data = this.data;
        this.phaseData_device = {
          title: data.title,
          uid: data.id,
          model: data.model
        }
        if (this.fields && this.fields.length > 0) {
          if (this.data.model == 'swLora' || this.data.model == 'dmWater' || this.data.model == 'dmMaster') {

            this.phaseData_field = this.fields[0][0].value != "" ? this.fields[0][0] : this.fields[0][1];
          } else {
            this.phaseData_field = this.fields[0];
          }

        } else {
          this.phaseData_field = {};
        }
      }, 100)
    },
    fieldClick(item) {
      if (item.value != "") {       //如果字段为空，不展示今日数据
        this.phaseData_field = item;
      }
    },

    // 获取视频信息
    videoRequest() {
      // 兼容华为云视频和海康云视频
      let json;
      if (this.common.featureEnabled('huaweiVideo')) {
        // 华为云视频
        json = {
          "cmd": "videoUrl",
          "uid": this.data.id,
          "type": this.common.videoType(),  // 支持hls,rtmp和flv,不传时默认为hls
        }
        console.log('---华为云视频参数---', json)
      } else {
        // 海康云视频
        json = {
          "cmd": "dataStore",
          "id": this.data.id,
          "tag": "video"
        }
        console.log('---海康云视频参数---', json)
      }

      this.ws.send(json);
    },

    // 获取gps坐标
    gpsRequest() {
      let json = {
        "cmd": "dataStore",
        "id": this.data.id,
        "tag": "gps"
      }
      this.ws.send(json);
    },
    viewMenuList() {
      this.dialogObj = {
        flag: true,
        title: this.data.title,
        deviceUid: this.data.id,
        model: this.data.model
      }
    },
    // 监听
    onMsgFun() {
      // 获取视频信息 【华为】
      this.ws.addCallback('videoUrlAck', (res) => {
        this.videoData = {
          url: res.url,   // 视频地址	
          id: res.uid		// uid
        }
      })

      // 获取视频信息 【海康】
      this.ws.addCallback('dataStoreAck', (res) => {
        switch (res.tag) {
          case "video":
            for (let item of res.items) {
              if (item.id == this.data.id) {
                this.videoData = {
                  url: item.data.url ? item.data.url : '',
                  id: item.data.id ? item.data.id : '',
                  uid: this.data.id
                }
                break;
              }
            }
            break;
          case "gps":
            this.map_loading = true

            for (let item of res.items) {
              if (item.id == this.data.id) {
                if (JSON.stringify(item.data) == "{}") {
                  this.mapData.marker = {
                    lng: 116.404,
                    lat: 39.915
                  }
                  this.mapData.hasMarker = false
                } else {
                  let location = item.data.location;
                  if (!location || location.indexOf(",") == -1) {
                    break;
                  }
                  let locationArr = location.split(",");
                  this.mapData.marker = {
                    lng: locationArr[0],
                    lat: locationArr[1]
                  }
                  this.mapData.hasMarker = true
                }
                break;
              }
            }
            if (this.mapObj != null) {
              setTimeout(() => {
                var view = this.mapObj.getViewport(eval([this.mapData.marker]));
                // console.log(11111, view);
                this.mapData.zoom = view.zoom;
                this.mapData.center = view.center;
                this.map_loading = false
              }, 500)
            }
            break;
          default:
            break;
        }
      })
    }
  },
  mounted() {
    this.colors = this.common.cColors();
    this.onMsgFun();
  }
}
</script>

<style lang="scss">
@import "../assets/iconfont/icon_fields.css";
@import "../assets/iconfont/icon_menu_list.css";
// @import "../assets/iconfont/icon_dot.css";
// @import "../assets/iconfont/icon_fields.css";

#deviceInfo {
  height: calc(100vh - 160px);
  display: flex;
  flex-direction: row;

  .tab-node {
    display: inline-block;
    width: 76px;
    text-align: center;
  }

  .part1 {
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;

    .real-data {
      display: flex;
      flex-direction: column;
      margin-top: 0;

      .value {
        display: grid;
        grid-template-columns: repeat(
          auto-fill,
          minmax(150px, 1fr)
        ); //minmax(最小宽度，最大宽度)
        grid-row-gap: 20px; //行间距
        grid-column-gap: 20px; //列间距
      }

      .data-item {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border: 1px solid darken($color: $border_color, $amount: 5%);
        border-radius: 10px;
        padding: 10px 15px;

        &:hover {
          cursor: pointer;
          border-color: darken($color: $border_color, $amount: 25%);
        }

        .left {
          position: relative;

          i {
            color: $color_theme;

            &::before {
              font-size: 45px;
            }
          }

          span {
            position: absolute;
            left: 0;
            right: 0;
            line-height: 53px;
            text-align: center;
            color: $color_white;
            font-size: 24px;
          }
        }

        .right {
          display: flex;
          flex-direction: column;

          span {
            padding: 2px 0;
            text-align: right;

            &:first-child {
              font-size: 20px;
              font-weight: bold;
              color: lighten($color: $color_main, $amount: 10%);
            }

            &:last-child {
              font-size: 12px;
              color: lighten($color: $color_main, $amount: 10%);
            }
          }
        }
      }
    }

    .history-data {
      flex: auto;

      .value {
        height: calc(100% - 85px);
      }
    }
  }

  .part2 {
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    .video {
      margin-top: 0;
    }

    .map {
      flex: auto;

      .baidu-map {
        width: 100%;
        height: 100%;
      }
    }
  }

  .real-data,
  .history-data,
  .map,
  .video {
    background-color: $color_white;
    box-shadow: 0 0 10px 6px $border_color;
    border-radius: 20px;
    margin: 10px;

    .title {
      border-bottom: 1px solid $border_color;
      box-shadow: 0 1px 10px $border_color;
      padding: 20px;
    }

    .value {
      padding: 20px;
    }
  }

  .emty-class {
    color: #909399;
    text-align: center;
    padding: 20px 0 10px 0;
  }
}
.row_menu {
  margin-bottom: 10px;
  margin-left: 12px;
}
</style>
