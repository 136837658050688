<!-- 某一阶段的数据 -->
<template>
  <div id="phaseData">
    <div id="pChart" class="c-chart" v-loading="chartLoading" ref="pChart"></div>
    <!-- 特殊字段解释说明 -->
    <div class="explain" v-show="valueMaps.length>0">
      <div>
        <el-table :data="valueMaps" style="width: 100%; height: 100%;">
          <el-table-column prop="value" label="数值"></el-table-column>
          <el-table-column prop="title" label="说明"></el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
export default {
  data() {
    return {
      dataArr: [],
      chart: null,
      chartLoading: false,
      valueMaps: []
    }
  },
  props: {
    activeNodeName: String,
    device: {
      type: Object,
      default: {
        title: '', //设备名称
        uid: '', //设备UID
        model: '' //设备类型
      }
    },
    field: {
      type: Object
    }
  },
  watch: {
    'device.uid': {
      handler() {
        this.dataRequst();
        this.chart = null;
        console.log('--- device.uid -----');
      }
    },
    field: {
      handler() {
        console.log('--- field -----');
        if (this.dataArr.length == 0) {
          this.dataRequst();
        } else {
          if (this.device.model == 'swLora' || this.device.model == 'dmWater' || this.device.model == 'dmMaster') {
            if (Array.isArray(this.dataArr[this.activeNodeName])) {
              this.setChartData(this.dataArr[this.activeNodeName]);
            }
          } else {
            this.setChartData(this.dataArr);
          }
        }
      },
      deep: true
    },
    activeNodeName: {
      handler() {
        console.log('--- activeNodeName -----');
        if (this.dataArr.length == 0) {
          this.dataRequst();
        } else {
          if (this.device.model == 'swLora' || this.device.model == 'dmWater' || this.device.model == 'dmMaster') {
            if (Array.isArray(this.dataArr[this.activeNodeName])) {
              this.setChartData(this.dataArr[this.activeNodeName]);
            }
          } else {
            this.setChartData(this.dataArr);
          }
        }
      }
    }
  },
  methods: {
    dataRequst() {
      let date = this.common.cGetDate();
      let json = {
        "cmd": "history",
        "uid": this.device.uid,
        "date": date,
        "startTime": "000000",
        "stopTime": "235959",
        "pageItemCount": "10000",
        "timeOrder": "asc",
        "types": "8"
      }
      this.ws.send(json);
      this.chartLoading = true;
    },
    setChartData(dataArr) {
      if (dataArr == void 0 || dataArr.length == 0) {
        return;
      }
      if (this.field.valueMap) {
        let arr = new Array();
        for (let item in this.field.valueMaps) {
          arr.push({
            value: item,
            title: this.field.valueMaps[item]
          })
        }
        this.valueMaps = arr;
      } else {
        this.valueMaps = [];
      }
      let xData = new Array();
      let yData = new Array();
      for (let item of dataArr) {
        xData.push(item.time);
        if (item.datas) {
          let loop = 0; // 标记，判断若该时间点没有数据，添加空数据（若不添加，数据错乱）
          for (let item2 of item.datas) {
            loop++;
            // 时间点有数据，不再查找
            if (this.field.key && item2.key == this.field.key) {
              yData.push(this.field.valueMap ? item2.rawValue : item2.value);
              break;
            }
            // 直到最后都没有找到数据，添加空数据
            if (loop == item.datas.length) {
              yData.push("");
            }
          }
        }

      }
      let pChart = document.getElementById('pChart');
      if (pChart) {
        this.$nextTick(() => {
          this.initChartData(xData, yData);
          window.onresize();
        })
      }

    },

    // echarts
    initChartData(xData, yData) {
      let option = {
        title: {
          text: this.field.title,
          subtext: this.field.unit ? '(' + this.field.unit + ')' : '',
          textStyle: {
            color: '#606266'
          },
          left: 'center'
        },
        color: '#4C95DE',
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        },
        xAxis: {
          type: 'category',
          data: xData
        },
        yAxis: {
          type: 'value'
        },
        grid: {
          top: '20%',
          bottom: '10%'
        },
        dataZoom: [{
          type: 'inside',
          show: false,
        }],
        toolbox: {
          left: 'right',
          feature: {
            dataZoom: {
              yAxisIndex: 'none'
            },
            magicType: {
              type: ['line', 'bar']
            },
            saveAsImage: {}
          }
        },
        series: [{
          data: yData,
          type: 'line',
          smooth: true,
          symbol: 'none',
          itemStyle: {
            normal: {
              lineStyle: {
                width: 4
              }
            }
          },
          markPoint: {
            itemStyle: {
              normal: {
                label: {
                  color: '#ffffff'
                }
              }
            },
            data: [{
              type: 'max',
              name: '最大值'
            },
            {
              type: 'min',
              name: '最小值'
            }
            ]
          },
          markLine: {
            data: [{
              type: 'average',
              name: '平均值'
            }]
          }
        }]
      };
      if (this.chart != null) {  //判断chart是否为null
        this.chart.setOption(option);

      }
      window.onresize = () => { if (this.chart) this.chart.resize() };
    },
    // updatedData (index) {
    // 	this.setChartData(this.dataArr[index])
    // }
    // 监听
    onMsgFun() {
      // 历史数据result
      this.ws.addCallback('historyAck', (res) => {
        this.$nextTick(() => {
          setTimeout(() => {
            this.chartLoading = false;
            let cDateToday = this.common.cGetDate();
            if (res.date != cDateToday || res.uid != this.device.uid) return false;
            let dataArr = new Array();
            for (let item of res.items) {
              let time = this.common.cComputationTime(item.time);
              let data;
              if (this.device.model == 'swLora' || this.device.model == 'dmWater' || this.device.model == 'dmMaster') {
                data = this.parseProfile.parseData2(this.device.model, item.data);
              } else {
                data = this.parseProfile.parseData(this.device.model, item.data);
              }
              dataArr.push({
                time: time,
                datas: data
              })
            }

            // 初始化Chart
            if (!this.chart) {
              this.chart = echarts.init(this.$refs.pChart);
              this.chart.clear();
            }
            // 没有数据，则初始化
            if (dataArr.length == 0) {
              this.chart.setOption([]);
              return false;
            }
            // 图表
            if (this.device.model == 'swLora' || this.device.model == 'dmWater' || this.device.model == 'dmMaster') {
              if (dataArr.length > 0) {
                //节点个数
                let chartCounts = dataArr[0].datas.length
                let dataArr2 = new Array();

                for (let i = 0; i < chartCounts; i++) {
                  let dataArr2child = new Array();
                  dataArr.forEach(e => {
                    dataArr2child.push({
                      time: e.time,
                      datas: e.datas[i]
                    })

                  })
                  dataArr2.push(dataArr2child)
                }
                this.dataArr = dataArr2;
                this.setChartData(dataArr2[this.activeNodeName]);
              }
            } else {
              this.dataArr = dataArr;
              this.setChartData(dataArr);
            }
          }, 200)
        })
      })
    }
  },
  mounted() {
    this.onMsgFun();
  }
}
</script>

<style lang="scss">
#phaseData {
  height: 100%;
  position: relative;

  .explain {
    // position: absolute;
    width: 200px;
    max-height: 85px;
    overflow: auto;
    // top: 0;
    // left: 0;
    z-index: 1;
    border: 1px solid #dddddd;
    border-radius: 4px;
    // opacity:0.5;
    margin: 0 auto;
  }

  // 表格
  .el-table {
    border-radius: 4px;
  }

  .el-table th,
  .el-table td {
    text-align: center !important;
    padding: 2px !important;
    font-size: 12px !important;
  }
  .c-chart {
    height: 400px;
  }
}
</style>
